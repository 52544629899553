import i18n from '../i18n';
import { SupportedLanguage, systemDefaultLanguageCode } from '../types/Languages';
import { Translations } from '../models/Translation';
import { ModuleTemplate, ModuleType } from '../models/Module';
import { PlatformIcons } from '../components/shared/icon/IconRender';
import { v4 as uuid } from 'uuid';
import { DefaultDownloadPdfPreferences } from '../models/DownloadPdfPreferences';

type ModuleLite = {
  translations?: Translations;
};

export default class ModuleUtils {
  public static getName(module: ModuleLite, lang: SupportedLanguage | null = null): string {
    const language: SupportedLanguage = lang || i18n.language;
    return module.translations?.[language]?.name || module.translations?.[systemDefaultLanguageCode]?.name || '';
  }

  public static getDescription(module: ModuleLite, lang: SupportedLanguage | null = null): string {
    const language: SupportedLanguage = lang || i18n.language;

    const translations = module.translations ? module.translations[language] || {} : {};
    return translations.description || '';
  }

  public static NewTemplate(
    clientId: string | undefined,
    type: ModuleType = ModuleType.Default,
    sortOrder: number,
    defaultSectionName: string,
  ): ModuleTemplate {
    const template: ModuleTemplate = {
      sortOrder: sortOrder,
      id: uuid(),
      iconCode: PlatformIcons.AUDIT_ICON,
      isLocked: false,
      translations: {
        [systemDefaultLanguageCode]: {
          name: defaultSectionName,
        },
      },
      clientId: clientId,
      type: type,
      requiresApproval: false,
      requiresValidation: false,
      downloadPdfPreferences: DefaultDownloadPdfPreferences,
      sections: [
        {
          id: uuid(),
          translations: {},
          forms: [],
        },
      ],
    };
    return template;
  }
}
